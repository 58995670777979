var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-modal", {
        attrs: {
          show: _vm.openQR,
          close: _vm.closeQR,
          "has-buttons": false,
          width: "400px",
        },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [_c("app-addr-qr")]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-row",
        { staticClass: "pa-2 backgroundGrey border-radius--5px" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                {
                  attrs: { "align-content": "center", justify: "space-around" },
                },
                [
                  _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
                    _c(
                      "p",
                      { staticClass: "font-weight-bold ma-0" },
                      [
                        _c("v-icon", { staticClass: "black--text mew-body" }, [
                          _vm._v(" mdi-information-outline "),
                        ]),
                        _vm._v(
                          " Your " +
                            _vm._s(_vm.currencyName) +
                            " balance is too low "
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                    _c("div", { staticClass: "mew-body textMedium--text" }, [
                      _vm._v(
                        " Every transaction requires a small amount of " +
                          _vm._s(_vm.currencyName) +
                          " to execute. Even if you have tokens to swap, when your " +
                          _vm._s(_vm.currencyName) +
                          " balance is close to zero, you won't be able to send anything until you fund your account. "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mew-body greenPrimary--text font-weight-medium cursor--pointer",
                          on: { click: _vm.openBarcodeModal },
                        },
                        [
                          _vm._v(
                            " Transfer " +
                              _vm._s(_vm.currencyName) +
                              " from another account "
                          ),
                        ]
                      ),
                      _c("br"),
                      _vm.isEthNetwork
                        ? _c(
                            "a",
                            {
                              staticClass: "mew-body font-weight-medium",
                              attrs: { rel: "noopener noreferrer" },
                              on: { click: _vm.openBuySell },
                            },
                            [_vm._v(" Buy " + _vm._s(_vm.currencyName) + " ")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }